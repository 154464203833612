<template>
    <div v-loading.fullscreen.lock="loading">
        <div>
            <div class="mx-2 text-lg font-bold">VENTAS</div>
            <div v-for="payment_method in totalPaymentMethod" :key="payment_method.name" class="mb-2">
                <div class="w-full border p-2 flex flex-row justify-between bg-gray-200">
                    <strong class="text-md uppercase">
                        {{ payment_method.name }}
                    </strong>
                    <strong class="text-md uppercase">
                        {{ moneyFormat(payment_method.total) }}
                    </strong>
                </div>
                <div class="w-full border p-2">
                    <div v-for="order in payment_method.orders" :key="order.id" class="border-b mb-4">
                        <div class="w-full flex flex-col lg:flex-row">
                            <div class="w-full lg:w-2/12">
                                <a @click="$router.push({ name: 'orders.read', params: { id: order.id } })">
                                    <strong>#: </strong>
                                    {{ order.reference }}
                                </a>
                            </div>
                            <div class="w-full lg:w-3/12">
                                <strong>Tipo: </strong>
                                {{ order.type.label }}
                            </div>
                            <div class="w-full lg:w-3/12">
                                <strong>Impuestos: </strong>
                                {{ moneyFormat(order.taxAmount) }}
                            </div>
                        </div>
                        <div class="w-full" v-if="order.orderProducts.length">
                            <strong>Productos: </strong>
                            <div>
                                <ul>
                                    <li v-for="product in order.orderProducts" :key="product.id"
                                        class="w-full flex flex-row">
                                        <div class="w-full lg:w-6/12">
                                            - <strong>Nombre: </strong>{{ product.name }}
                                        </div>
                                        <div class="w-full lg:w-3/12">
                                            <strong>Cantidad: </strong>{{ product.quantity }}
                                        </div>
                                        <div class="w-full text-right lg:w-3/12">
                                            <strong>Precio: </strong>{{ moneyFormat(product.price) }}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="w-full text-justify">
                            <div class="w-full py-4">
                                <!--REPAIRS AND MIXED-->
                                <div class="border p-2" v-for="orderDevice in order.orderDevices" :key="orderDevice.id">
                                    <div class="w-full flex flex-col lg:flex-row">
                                        <div class="w-full lg:w-9/12">
                                            <strong>Dispositivo: </strong>
                                            <span>{{ `${orderDevice.deviceModel} ${orderDevice.deviceBrand}` }}</span>
                                        </div>
                                        <div class="w-full text-right lg:w-3/12 text-lg">
                                            <strong>Desglose: </strong>
                                            <span>{{ moneyFormat(getOrderDeviceTotal(orderDevice)) }}</span>
                                        </div>
                                    </div>
                                    <div class="w-full" v-if="!!orderDevice.orderDeviceServices.length">
                                        <strong>Servicios: </strong>
                                        <div>
                                            <ul>
                                                <li v-for="service in orderDevice.orderDeviceServices" :key="service.id"
                                                    class="w-full flex flex-row">
                                                    <div class="w-full lg:w-6/12">
                                                        - <strong>Categoria: </strong>{{
                                                            getOrderServiceCategoryName(service.serviceCategoryId) }}
                                                    </div>
                                                    <div class="w-full lg:w-3/12">
                                                        <strong>Cantidad: </strong>1
                                                    </div>
                                                    <div class="w-full text-right lg:w-3/12">
                                                        <strong>Precio: </strong>{{ moneyFormat(service.price) }}
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="w-full" v-if="!!orderDevice.orderDeviceProducts.length">
                                        <strong>Productos: </strong>
                                        <div>
                                            <ul>
                                                <li v-for="product in orderDevice.orderDeviceProducts" :key="product.id"
                                                    class="w-full flex flex-row">
                                                    <div class="w-full lg:w-6/12">
                                                        - <strong>Nombre: </strong>{{ product.name }}
                                                    </div>
                                                    <div class="w-full lg:w-3/12">
                                                        <strong>Cantidad: </strong>{{ product.quantity }}
                                                    </div>
                                                    <div class="w-full text-right lg:w-3/12">
                                                        <strong>Precio: </strong>{{ moneyFormat(product.price) }}
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!!totalExpenseMethod.length">
            <div class="mx-2 text-lg font-bold">GASTOS</div>
            <div v-for="expenseMethod in totalExpenseMethod" :key="expenseMethod.name" class="mb-2">
                <div class="w-full border p-2 flex flex-row justify-between bg-gray-200">
                    <strong class="text-md uppercase">
                        {{ expenseMethod.name }}
                    </strong>
                    <strong class="text-md uppercase">
                        {{ moneyFormat(expenseMethod.total) }}
                    </strong>
                </div>
                <div class="w-full border p-2">
                    <div v-for="expense in expenseMethod.expenses" :key="expense.id" class="border-b py-4">
                        <div class="w-full flex flex-col lg:flex-row">
                            <div class="w-full lg:w-2/12">
                                <strong>Tipo: </strong>
                                {{ ExpenseTypesDict[expense.expenseType] }}
                            </div>
                            <div class="w-full lg:w-3/12">
                                <strong>Categoria: </strong>
                                {{ getExpenseCategory(expense) }}
                            </div>
                            <div class="w-full lg:w-3/12">
                                <strong>Subcategoria: </strong>
                                {{ getExpenseSubcategory(expense) }}
                            </div>
                            <div class="w-full lg:w-3/12 text-lg">
                                <strong>Desglose: </strong>
                                {{ moneyFormat(expense.amount) }}
                            </div>
                        </div>
                        <div class="w-full text-justify py-2">
                            <div class="w-full">
                                <div class="w-full" v-if="expense.observations">
                                    <strong>Observaciones: </strong>
                                    <span>{{ expense.observations }}</span>
                                </div>
                                <div class="w-full" v-if="expense.reference">
                                    <strong>Referencia: </strong>
                                    <span>{{ expense.reference }}</span>
                                </div>
                                <div class="w-full" v-if="expense.expenseType === ExpenseType.Product">
                                    <strong>Productos: </strong>
                                    <div>
                                        <table class="w-full">
                                            <thead>
                                                <tr>
                                                    <th>Nombre</th>
                                                    <th>Cantidad</th>
                                                    <th>Costo</th>
                                                    <th>Categoria</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="product in JSON.parse(expense.products)" :key="product.id">
                                                    <td>{{ product.Name }}</td>
                                                    <td>{{ product.Quantity }}</td>
                                                    <td>{{ moneyFormat(product.Cost) }}</td>
                                                    <td>{{ getProductCategory(product.Category) }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-1/2 lg:w-4/12 ml-auto text-right">
            <div class="w-full flex flex-row justify-between">
                <div>
                    Venta Neta:
                </div>
                <div class="text-right">
                    {{ moneyFormat(partialSales) }}
                </div>
            </div>
            <div class="w-full flex flex-row justify-between">
                <div>
                    Venta Total:
                </div>
                <div class="text-right">
                    {{ moneyFormat(totalSales) }}
                </div>
            </div>
            <div class="w-full flex flex-row justify-between">
                <div>
                    Total Gastos:
                </div>
                <div class="text-right">
                    {{ moneyFormat(totalExpenses * -1) }}
                </div>
            </div>
            <div class="w-full flex flex-row justify-between">
                <div>
                    Total de Impuestos:
                </div>
                <div class="text-right">
                    {{ moneyFormat(totalTaxes * -1) }}
                </div>
            </div>
            <div class="w-full flex flex-row justify-between">
                <div>
                    <i class="bx bx-info-circle" title="Total Ventas - Total Impuestos - Total Gastos"></i>
                    &nbsp;Total Utilidad:
                </div>
                <div class="text-right">
                    {{ moneyFormat(utility) }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import _ from 'lodash';
import error from '@/mixins/error';
import numbers from '@/utils/numbers';
import OrderService from '@/services/OrderService';
import { getOrderType } from '@/utils/orders';
import { OrderTypesEnum, ExpenseType, ExpenseTypesDict, ExpenseCategoryDict } from '@/constants';

export default {
    name: 'BusinessCloseDay',
    mixins: [error],
    props: {
        businessInfoId: {
            type: String,
            required: true,
        }
    },
    mounted() {
        this.load();
    },
    data() {
        return {
            loading: false,
            totalSales: 0,
            partialSales: 0,
            totalPaymentMethod: [],
            totalExpenses: 0,
            totalExpenseMethod: [],
            totalTaxes: 0,
            OrderTypesEnum,
            ExpenseTypesDict,
            ExpenseType,
        }
    },
    computed: {
        utility() {
            const sales = this.totalSales;
            const taxes = this.totalTaxes;
            const expenses = this.totalExpenses;

            return sales - taxes - expenses;
        },
    },
    methods: {
        getOrderDeviceTotal(orderDevice) {
            const services = orderDevice.orderDeviceServices.reduce((acc, curr) => acc + parseFloat(curr.price), 0);
            const products = orderDevice.orderDeviceProducts.reduce((acc, curr) => acc + (parseFloat(curr.price) * curr.quantity), 0);

            return services + products;
        },
        moneyFormat(value) {
            const { currencySymbol } = this.$store.state.account;
            return numbers.moneyFormat(value, currencySymbol);
        },
        getOrderServiceCategoryName(categoryId) {
            const { categories } = this.$store.state.catalogs.order.service;
            const category = categories.find(({ id }) => id === categoryId);
            return category?.name;
        },
        getFirstExpenseProduct(expense) {
            const products = JSON.parse(expense.products);
            const category = this.$store.state.catalogs.product.categories.find((pc) => pc.id === products[0].Category);
            const name = products[0].Name;

            return {
                category,
                name,
            }
        },
        getProductCategory(categoryId) {
            const { categories } = this.$store.state.catalogs.product;
            const category = categories.find(({ id }) => id === categoryId);
            return category?.name;
        },
        getExpenseCategory(expense) {
            if (expense.expenseType === ExpenseType.Product) {
                const product = this.getFirstExpenseProduct(expense);
                return product.category?.name;
            }

            return ExpenseCategoryDict[expense.expenseCategory];
        },
        getExpenseSubcategory(expense) {
            const { expenseSubcategory } = expense;

            if (expense.expenseType === ExpenseType.Product) {
                const product = this.getFirstExpenseProduct(expense);
                return product.name;
            }

            if (_.isNil(expenseSubcategory)) {
                return 'N/A';
            }

            const staticSubcategory = this.$store.state.expenses.subcategories.find((sub) => sub.id === expenseSubcategory);
            if (!_.isNil(staticSubcategory)) {
                return _.get(staticSubcategory, 'name', 'N/A');
            }

            return expenseSubcategory;
        },
        async load() {
            this.loading = true;
            try {
                const dailySales = await OrderService.getDailySales({
                    businessInfoId: this.businessInfoId,
                    date: this.$moment(new Date()).format('MM/DD/YYYY'),
                });
                this.partialSales = dailySales.partialSales;
                this.setTotalSales(dailySales.totalPaymentMethod);
                this.setTotalExpenses(dailySales.totalExpenseMethod);
            } catch (error) {
                const errorMessage = this.getErrorMessage(error);
                this.$toastr.e(errorMessage);
            } finally {
                this.loading = false;
            }
        },
        setTotalSales(totalPaymentMethods) {
            this.totalPaymentMethod = totalPaymentMethods.map(({ orders, paymentMethod, total }) => {
                this.totalSales += parseFloat(total);

                return {
                    name: paymentMethod,
                    total: total,
                    orders: orders.map((order) => {
                        this.totalTaxes += parseFloat(order.taxAmount);

                        return {
                            ...order,
                            type: getOrderType(order),
                        }
                    }),
                };
            });
        },
        setTotalExpenses(totalExpenseMethods) {
            this.totalExpenseMethod = totalExpenseMethods.map(({ expenses, paymentMethod, total }) => {
                this.totalExpenses += parseFloat(total);

                return {
                    name: paymentMethod,
                    total: total,
                    expenses: expenses.map((expense) => ({
                        ...expense,
                    })),
                };
            });
        },
    },
}
</script>